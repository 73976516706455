header {
    .header__customer-switch {
        padding: .4rem 0;

        .v-select {
            input, input:focus {
                line-height: 2.8rem;
                font-size: 1.4rem;
            }
        }
    }

    .header {
        &__buttons {
            align-self: center;
            display: flex;
            font-size: 1.4rem;
            gap: 2rem;
            .--login {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;'%3E%3Crect id='Artboard1' x='0' y='0' width='21' height='21' style='fill:none;'/%3E%3Cg id='Artboard11' serif:id='Artboard1'%3E%3Cpath d='M19,17.784l-0,-2.222c-0,-2.844 -2.284,-5.151 -5.1,-5.151l-0.794,0c1.35,-0.871 2.251,-2.39 2.251,-4.128c0,-2.71 -2.174,-4.906 -4.857,-4.906c-2.683,-0 -4.857,2.196 -4.857,4.906c-0,1.738 0.901,3.257 2.251,4.128l-0.794,0c-2.816,0 -5.1,2.307 -5.1,5.151l-0,2.222c-0,1.015 0.816,1.839 1.821,1.839l13.358,0c1.005,0 1.821,-0.824 1.821,-1.839Z' style='fill:none;fill-rule:nonzero;stroke:%23fff;stroke-width:1.5px;'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left 2rem center;
                background-size: 2rem;
                padding: 0 3rem 0 5rem;
            }
            .--locator {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Crect id='Artboard1' x='0' y='0' width='21' height='21' style='fill:none;'/%3E%3CclipPath id='_clip1'%3E%3Crect id='Artboard11' serif:id='Artboard1' x='0' y='0' width='21' height='21'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23_clip1)'%3E%3Cpath d='M9.8,19.137c0.338,0.484 1.062,0.484 1.4,-0c5.156,-7.405 6.113,-8.165 6.113,-10.887c-0,-3.728 -3.05,-6.75 -6.813,-6.75c-3.763,-0 -6.813,3.022 -6.813,6.75c0,2.722 0.957,3.482 6.113,10.887Zm0.7,-8.075c-1.568,0 -2.839,-1.259 -2.839,-2.812c0,-1.553 1.271,-2.813 2.839,-2.813c1.568,0 2.839,1.26 2.839,2.813c-0,1.553 -1.271,2.812 -2.839,2.812Z' style='fill:none;fill-rule:nonzero;stroke:%23fff;stroke-width:1.5px;'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left 2rem center;
                background-size: 2rem;
                padding: 0 3rem 0 5rem;
            }
        }

        &__menu {
            &--toggle {
                background-image: url("data:image/svg+xml,%0A%3Csvg height='16' viewBox='0 0 24 16' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231d1d1b' fill-rule='evenodd'%3E%3Cpath d='m0 0h24v2h-24z'/%3E%3Cpath d='m0 7h24v2h-24z'/%3E%3Cpath d='m0 14h24v2h-24z'/%3E%3C/g%3E%3C/svg%3E");
                display: block;
                height: 1.6rem;
                width: 2.4rem;
                @include breakpoint(small only) {
                    background-image: url("data:image/svg+xml,%0A%3Csvg height='16' viewBox='0 0 24 16' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='#{color(base, White)}' fill-rule='evenodd'%3E%3Cpath d='m0 0h24v2h-24z'/%3E%3Cpath d='m0 7h24v2h-24z'/%3E%3Cpath d='m0 14h24v2h-24z'/%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }

        @include breakpoint(between-large-medium) {
            .cart__holder {
                margin: 25px 0 0 5px;

                &.is-stuck {
                    margin: 15px 0 0 5px;
                }
            }

            .customer__link {
                margin: 25px 0 0 5px;

                &.is-stuck {
                    margin: 15px 0 0 5px;
                }
            }
        }

        &__logo {
            .--logo {
                display: inline-flex;
                justify-content: flex-start;

                @include breakpoint(small only) {
                    margin: .5rem 0;
                    max-width: 16rem;
                }

                img {
                    @include transition;
                }
            }
        }
    }

    .top__header--hero {
        .header__brands--container {
            position: relative;
            > span {
                position: relative;
                line-height: 4rem;
                display: block;
                border: .1rem solid silver;
                border-radius: .3rem;
                padding: 0 1rem;
                font-weight: 600;
                cursor: pointer;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, DefaultFontColor)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: right 1rem center;
                background-size: 2rem;

                &.--active {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, DefaultFontColor)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 15.75 7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                }
            }
            .header__brands--menu {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 20;
                background: color(base, DefaultFontColor);
                border: .1rem solid color(gray, Gray-50);
                padding: 1rem;
                max-height: 50rem;
                overflow-y: scroll;
                &.--active {
                    display: flex;
                    flex-wrap: wrap;
                }
                a {
                    color: color(base, DefaultFontColor);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: .5rem;
                    width: 100%;
                    padding: 1rem .5rem;
                    border-bottom: .1rem solid color(base, White);
                    &:last-of-type {
                        border: none;
                    }

                    &:hover {
                        background: color(base, Black);
                    }
                    &.--active {
                        background: color(base, DefaultFontColor);
                    }
                    img {
                        max-height: 5rem;
                        width: auto;
                        flex: 1;
                    }
                    > div {
                        flex: 0 0 80%;
                        span {
                            color: color(base, White);
                            line-height: 2.1rem;
                        }
                    }
                }
            }
        }
    }

    .header__logo {
        display: flex;
        justify-content: flex-start;
        .--logo {
            img {
                max-height: 7.5rem;
                width: 100%;
            }
        }
    }

    .mobile__buttons {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        @include breakpoint(small only) {
            display: block;
        }

        section {
            flex: 0 0 3.5rem;
            height: 3.5rem;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
            background-position: center;
            background-image: url('data:image/svg+xml,<svg id="tekenlaag" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><title>SD_Icon_cart_blk</title><ellipse cx="57.13" cy="116.14" rx="10.58" ry="8.27" transform="translate(-62.6 66.98) rotate(-41.48)"/><ellipse cx="115.45" cy="116.14" rx="10.58" ry="8.27" transform="translate(-47.97 105.61) rotate(-41.48)"/><path d="M111.37,105.88H70.84A13.81,13.81,0,0,1,57.48,88.56L70.67,38.43,59.2,34.61a3.66,3.66,0,1,1,2.31-6.95l18,6-15,56.77a6.48,6.48,0,0,0,6.28,8.13h40.53a3.67,3.67,0,0,1,0,7.33Z"/><path d="M117.91,89.71H67.79l12-41.84,60.76,14.35-4.88,14.69A18.68,18.68,0,0,1,117.91,89.71Zm-40.4-7.32h40.4A11.34,11.34,0,0,0,128.7,74.6l2.36-7.09L84.9,56.61Z"/><path d="M38.09,112H4.56a.61.61,0,0,1-.62-.61.62.62,0,0,1,.62-.61H38.09a.61.61,0,0,1,.61.61A.61.61,0,0,1,38.09,112Z"/><path d="M47.9,100.47H23.54a.62.62,0,0,1,0-1.23H47.9a.62.62,0,0,1,0,1.23Z"/><path d="M45.68,28.24H21.32a.62.62,0,0,1-.61-.61.61.61,0,0,1,.61-.61H45.68a.61.61,0,0,1,.61.61A.61.61,0,0,1,45.68,28.24Z"/></svg>');

            .--amount {
                display: none;
            }
        }
    }

    .header__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: .8rem;
        width: 100%;

        > a, > section > a {
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
            background-position: center;

            .--amount {
                font-size: 1.2rem;
                color: color(base, White);
                font-weight: 700;
                background-color: color(base, DefaultFontColor);
                height: 1.5rem;
                position: absolute;
                left: 1.5rem;
                top: -.4rem;
                width: 1.5rem;
                text-align: center;
            }

            .--totals {
                line-height: 3.5rem;
                padding-left: 3.5rem;
                color: color(Motoport, Grey);
            }

            &.customer__account-icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, DefaultFontColor)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z' /%3E%3C/svg%3E%0A");
                background-size: 2.8rem;
                width: 3.5rem;
                height: 3.5rem;
                margin-left: auto;
            }

            &.customer__cart-icon {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 188 198' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Crect id='Artboard1' x='0' y='0' width='187.899' height='197.232' style='fill:none;'/%3E%3CclipPath id='_clip1'%3E%3Crect id='Artboard11' serif:id='Artboard1' x='0' y='0' width='187.899' height='197.232'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23_clip1)'%3E%3Cg%3E%3Cpath d='M12.419,57.3c-4.608,-0 -8.341,3.733 -8.341,8.341l-0,110.696c-0,9.217 7.471,16.688 16.683,16.688l146.479,-0c9.217,-0 16.688,-7.471 16.688,-16.688l-0,-110.696c-0,-4.608 -3.734,-8.341 -8.342,-8.341l-163.167,-0Z' style='fill:none;fill-rule:nonzero;stroke:#{color(base, DefaultFontColor)};stroke-width:15px;'/%3E%3Cpath d='M137.389,79.215l0,-58.462c0,-9.217 -7.471,-16.688 -16.687,-16.688l-53.4,0c-9.217,0 -16.688,7.471 -16.688,16.688l0,58.462' style='fill:none;fill-rule:nonzero;stroke:#{color(base, DefaultFontColor)};stroke-width:15px;stroke-linecap:round;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-size: 2.4rem;
                background-position: left center;
                width: 3.5rem;
                height: 3.5rem;
                &.--has-items {
                    padding-right: 5rem;
                    @include breakpoint(small only) {
                        padding-right: 0;
                    }
                }
            }
        }

        section {
            width: initial;
            height: 3.5rem;
            flex: 0 0 3.5rem;
            display: flex;
        }
    }

    .sticky {
        background: color(base, White);

        @include breakpoint(small only) {
            background: color(Motoport, DarkGrey);
        }

        @include breakpoint(medium) {
            &.is-stuck {
                .--logo img {
                    height: 9rem;
                    width: auto;
                }

                .header__autocomplete .search--form__holder {
                    padding: .9rem 0;
                }
            }
        }
    }

    .header__container--wrapper {
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .header__autocomplete {
        display: flex;
        gap: 1.5rem;

        .search--form__holder {
            position: relative;
            padding: .5rem 0;
            width: 100%;

            @include breakpoint(medium) {
                padding: 2rem 0;
                width: 80%;
            }
        }
    }
}

.top__header--hero {
    margin: 2rem 0;
    padding: 0 2rem;
    .--brands {
        > span {
            line-height: 3.4rem;
            height: 3.4rem;
            border: .1rem solid color(Gray, Gray-300);
            border-radius: .3rem;
            padding: 0 1rem;
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 2rem;
            background-position: right 1rem center;
        }
    }
    .--locator {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: .5rem 0;
        > a {
            flex: 1;
            height: 4rem;
            &.--login {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;'%3E%3Crect id='Artboard1' x='0' y='0' width='21' height='21' style='fill:none;'/%3E%3Cg id='Artboard11' serif:id='Artboard1'%3E%3Cpath d='M19,17.784l-0,-2.222c-0,-2.844 -2.284,-5.151 -5.1,-5.151l-0.794,0c1.35,-0.871 2.251,-2.39 2.251,-4.128c0,-2.71 -2.174,-4.906 -4.857,-4.906c-2.683,-0 -4.857,2.196 -4.857,4.906c-0,1.738 0.901,3.257 2.251,4.128l-0.794,0c-2.816,0 -5.1,2.307 -5.1,5.151l-0,2.222c-0,1.015 0.816,1.839 1.821,1.839l13.358,0c1.005,0 1.821,-0.824 1.821,-1.839Z' style='fill:none;fill-rule:nonzero;stroke:%23fff;stroke-width:1.5px;'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left 2rem center;
                background-size: 2rem;
                padding: 0 3rem 0 5rem;
            }
            &.--locator {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Crect id='Artboard1' x='0' y='0' width='21' height='21' style='fill:none;'/%3E%3CclipPath id='_clip1'%3E%3Crect id='Artboard11' serif:id='Artboard1' x='0' y='0' width='21' height='21'/%3E%3C/clipPath%3E%3Cg clip-path='url(%23_clip1)'%3E%3Cpath d='M9.8,19.137c0.338,0.484 1.062,0.484 1.4,-0c5.156,-7.405 6.113,-8.165 6.113,-10.887c-0,-3.728 -3.05,-6.75 -6.813,-6.75c-3.763,-0 -6.813,3.022 -6.813,6.75c0,2.722 0.957,3.482 6.113,10.887Zm0.7,-8.075c-1.568,0 -2.839,-1.259 -2.839,-2.812c0,-1.553 1.271,-2.813 2.839,-2.813c1.568,0 2.839,1.26 2.839,2.813c-0,1.553 -1.271,2.812 -2.839,2.812Z' style='fill:none;fill-rule:nonzero;stroke:%23fff;stroke-width:1.5px;'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left 2rem center;
                background-size: 2rem;
                padding: 0 3rem 0 5rem;
            }
        }
    }
}
.header__customer-switch {
    color: color(Motoport, Black);
    max-width: 275px;
    width: 100%;
}
.top__header__container {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 2rem;
}
