.homepage__fullscreen-hero--block-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .top__block {
        flex: 1;

        > div {
            height: 100%;
            background-size: cover;
            overflow: hidden;
        }
    }

    .bottom__block {
        display: flex;
        overflow: hidden;
        flex: 0 0 20%;

        @include breakpoint(small only) {
            flex-flow: column;
            height: 100%;
            flex: 1;
        }

        > a {
            flex: 1;
            position: relative;

            > img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .--overlay {
                transition: width .5s ease-in-out;
                position: absolute;
                background-color: color(base, SemiSoldDark);
                z-index: 1;
                top: 0;
                bottom: 0;
                width: 3rem;
                right: 0;
                transform: skewX(-7deg);

                .--inner {
                    transition: opacity .5s ease-in-out;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-flow: column;
                    height: 100%;
                    transform: skewX(7deg);
                    opacity: 0;

                    > span {
                        display: block;
                        color: color(base, White);
                        line-height: 1;
                        font-style: italic;

                        &:first-child {
                            font-weight: 900;
                            font-size: 3rem;
                            position: relative;
                            margin: 0 0 .5rem;

                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 100%;
                                left: -1rem;
                                right: -1rem;
                                height: .2rem;
                                background: color(base, White);
                            }
                        }

                        &:last-child {
                            font-size: 1.6rem;
                            font-style: italic;
                            padding-left: 17%;
                        }
                    }
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 1rem;
                    background: color(base, White);
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    right: calc(100% - .1rem);
                    top: 50%;
                    transform: translateY(-50%);
                    border-style: solid;
                    border-width: 1.5rem 1.5rem 1.5rem 0;
                    border-color: transparent color(base, White) transparent transparent;
                }
            }

            > span.--title {
                background: color(base, White);
                position: absolute;
                z-index: 2;
                color: color(base, DefaultFontColor);
                text-transform: uppercase;
                display: flex;
                align-items: center;
                bottom: 0;
                right: 0;
                left: 0;
                justify-content: space-between;
                padding: .5rem 2rem;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-width: 0 1.8rem 1.3rem;
                    border-color: transparent transparent color(base, White) transparent;
                    border-style: solid;
                }

                span:first-child {
                    font-weight: 900;
                    font-size: 2.2rem;
                    font-style: italic;

                    @include breakpoint(medium) {
                        font-style: initial;
                        font-size: clamp(2rem, 1.545rem + 0.909vw, 3rem);
                        text-orientation: mixed;
                        writing-mode: vertical-rl;
                        transform: scale(-1);
                    }
                }

                span:last-child {
                    font-weight: 100;
                    font-style: italic;
                    font-size: .9rem;
                }

                @include breakpoint(medium) {
                    padding: 0;
                    right: -2rem;
                    top: 0;
                    left: initial;
                    transform: skewX(-7deg);
                    width: 5rem;
                    justify-content: center;

                    &::before {
                        display: none;
                    }
                }
            }

            @include hover() {
                .--overlay {
                    width: 100%;

                    .--inner {
                        opacity: 1;
                    }
                }
            }

            @include breakpoint(medium) {

                &:last-child {
                    .--overlay {
                        width: 5.9rem;
                    }

                    > span.--title {
                        right: 0;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 110%;
                            height: 100%;
                            width: 2rem;
                            background: color(base, White);
                        }
                    }
                }

                &:first-child {

                    @include hover() {
                        .--overlay {
                            width: calc(100% + 2.5rem);
                        }
                    }
                }
            }
        }
    }
}
