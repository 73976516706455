.page-builder__wrapper {
    overflow: hidden;
    @include breakpoint(medium) {
        margin: 2rem 0 0;
    }

    .page-builder__content-block:not(.--first) {
        margin: 2rem 0;
        @include breakpoint(medium) {
            margin: 7rem 0;
        }

        &:not(.--last) {
            .horizontal__line {
                margin: 4rem 0 0;
                border-bottom: .5rem solid color(Motoport, MotoGrey);

                @include breakpoint(medium) {
                    margin: 7rem 0 0;
                }
            }
        }
    }

    .--header {
        margin: 0 0 3rem;

        h3 {
            text-align: center;
            font-size: 2.4rem;
        }
    }
}
